<template>
  <Select
    v-if="edit"
    id="carrier-options"
    v-model="model"
    class="carrier-select"
    :options="carrierOptions"
    :reduce="(carrier) => carrier.id"
    @input="onInput"
  />
  <span v-else class="energy-carrier-picker" :class="classes">{{ selectedLabel }}</span>
</template>

<script>
import Select from '@/components/shared/forms/Select.vue'

export default {
  components: {
    Select,
  },
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
    },
    exclude: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
  },

  emits: ['input'],

  data() {
    return {
      model: null,
    }
  },

  computed: {
    carrierOptions() {
      const options = []
      for (let index in this.portfolio.energy_carriers) {
        options.push({
          id: this.portfolio.energy_carriers[index].id,
          name: this.portfolio.energy_carriers[index].name,
          label: this.portfolio.getEnergyCarrierName((k) => this.$t(k), index),
        })
      }
      return options.filter((o) => !this.exclude || !this.exclude.includes(o.name))
    },

    selected() {
      return this.carrierOptions.find((o) => o.id === this.model)
    },

    classes() {
      return {
        'dynamic': ['ELECTRICITY_DYNAMIC', 'ELECTRICITY_DYNAMIC_WITH_PV'].includes(this.selected?.name)
      }
    },

    selectedLabel() {
      return this.selected === undefined ? null : this.selected.label
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
  },

  created() {
    this.model = this.value
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },
  },
}
</script>

<style>
.carrier-select {
  width: 100%;
}
</style>
